import React from 'react';
import {MapControls, PresentationMap, DeckGlMap} from '@gisatcz/ptr-maps';
import {HoverHandler} from '@gisatcz/ptr-core';
import {Icon} from '@gisatcz/ptr-atoms';
import MapOverlay from '../MapOverlay';

import './style.scss';

import {background, metro, poi} from '../../data/contact/map/layers';

const view = {
	center: {lat: 50.0985, lon: 14.4225},
	boxRange: 2000,
};

const layers = [metro, poi];

const Tooltip = props => {
	const data = props?.feature?.properties;

	return (
		<div className="gst-map-tooltip">
			<div className="gst-map-tooltip-title">{data.name}</div>
			{data.description ? (
				<div className="gst-map-tooltip-description">{data.description}</div>
			) : null}
		</div>
	);
};

// const resources = {
// 	icons: {
// 		gisat: {
// 			component: Icon,
// 			componentProps: {icon: 'gisat'},
// 			anchorPoint: [0.5, 0.5],
// 		},
// 		pushpin: {
// 			component: Icon,
// 			componentProps: {icon: 'pushpin'},
// 			anchorPoint: [0.5, 1],
// 		},
// 	},
// };

class ContactMap extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className={'gst-contact-map'}>
				<MapOverlay>
					<PresentationMap
						mapComponent={DeckGlMap}
						mapKey="react-leaflet-map"
						view={view}
						backgroundLayer={background}
						layers={layers}
						Tooltip={Tooltip}
					>
						<div className="gst-contact-map-attribution">
							© GISAT Panther |{' '}
							<a
								href="https://deck.gl/"
								target="_blank"
								rel="noreferrer noopener"
							>
								deck.gl
							</a>{' '}
							| Background map: ©{' '}
							<a
								href="https://www.openstreetmap.org/copyright"
								target="_blank"
								rel="noreferrer noopener"
							>
								OpenStreetMap
							</a>{' '}
							contributors, ©{' '}
							<a
								href="https://carto.com/about-carto/"
								target="_blank"
								rel="noreferrer noopener"
							>
								CARTO
							</a>
						</div>
						<MapControls zoomOnly levelsBased />
					</PresentationMap>
				</MapOverlay>
			</div>
		);
	}
}

export default ContactMap;
