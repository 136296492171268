import React from 'react';

import Content from '../Content';

import './style.scss';

const PageNav = ({children}) => {
	return (
		<div className="gst-page-nav">
			<Content>
				<div className="gst-page-nav-content">{children}</div>
			</Content>
		</div>
	);
};

export default PageNav;
