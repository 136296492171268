import React from 'react';
import {graphql, Link, useStaticQuery} from 'gatsby';
import Collapsible from 'react-collapsible';
import {Button, Icon} from '@gisatcz/ptr-atoms';

import Layout from '../../components/Layout';
import SEO from '../../components/seo';
import Content from '../../components/Content';
import {lang} from '../../helpers/lang';
import PageNav from '../../components/PageNav';
import Block from '../../components/Block';
import ContactMap from '../../components/ContactMap';

import './style.scss';
import Accordion from '../../components/Accordion';
import Hero from '../../components/Hero';

const BaseContact = ({title, icon, children}) => (
	<div className="gst-base-contact">
		<div className="gst-base-contact-title">
			<span>{title}</span>
		</div>
		<div className="gst-base-contact-content">{children}</div>
	</div>
);

const ContactPage = () => {
	const data = useStaticQuery(graphql`
		{
			contactBackground: file(
				relativePath: {eq: "pages/contact/prague-tram.jpg"}
			) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
		}
	`);

	return (
		<Layout>
			<SEO title="Contact" />
			<PageNav>
				<Link to="/contact">
					<span>Get in touch</span>
				</Link>
				<Link to="#business">
					<span>Business details</span>
				</Link>
				<Link to="#location">
					<span>Location & transport</span>
				</Link>
			</PageNav>

			<Hero
				backgroundImageFluid={
					data.contactBackground.childImageSharp.gatsbyImageData
				}
				coloredOverlay
				className="gst-contact-intro"
			>
				<div className="gst-contact-intro-main">
					<div className="gst-primary-contact">
						<a href="mailto:gisat@gisat.cz">
							<Icon icon="mail" />
							<span>gisat@gisat.cz</span>
						</a>
					</div>
					<div className="gst-secondary-contact">
						<a href="tel:+420123456789">
							<Icon icon="phone" />
							<span>+420&nbsp;271&nbsp;741&nbsp;935</span>
						</a>
					</div>
					<div className="gst-follow-us">
						<span>Follow us</span>
						<div>
							<a
								target="_blank"
								rel="noreferrer noopener"
								href="https://www.linkedin.com/company/gisat/"
							>
								<Icon icon="linkedin" />
							</a>
							<a
								target="_blank"
								rel="noreferrer noopener"
								href="https://twitter.com/Gisat_cz"
							>
								<Icon icon="twitter" />
							</a>
							{/*<a*/}
							{/*	target="_blank"*/}
							{/*	rel="noreferrer noopener"*/}
							{/*	href="#"*/}
							{/*>*/}
							{/*	<Icon icon="facebook" />*/}
							{/*</a>*/}
						</div>
					</div>
				</div>
				<div className="gst-intro-footer">
					<div className="gst-intro-footer-item">
						<span>Looking for an expert in your field?</span>
						<Link to="/work#sectors">
							Select sector <Icon icon="back" />
						</Link>
					</div>
					<div className="gst-intro-footer-item">
						<span>Want to talk about a specific service?</span>
						<Link to="/work#services">
							Choose product/service <Icon icon="back" />
						</Link>
					</div>
					<div className="gst-intro-footer-item">
						<span>Want to talk with a specific person?</span>
						<Link to="/company#team">
							Tak a look at the team <Icon icon="back" />
						</Link>
					</div>
				</div>
			</Hero>
			<Block accent light className="gst-block-contact">
				<Content>
					<h2 id="business">Business details</h2>
					<div className="gst-base-contacts-box">
						<BaseContact title="Address">
							<div>GISAT s.r.o.</div>
							<div>Milady Horákové 57a</div>
							<div>170 00 Praha 7</div>
							<div>Czech Republic</div>
						</BaseContact>

						<BaseContact title="Company registration number (CRN)">
							26438003
						</BaseContact>
						<BaseContact title="VAT registration number">
							CZ26438003
						</BaseContact>

						<BaseContact title="Bank connection">
							<div>IBAN: CZ6220100000002900182961</div>
							<div>SWIFT code: FIOBCZPPXXX</div>
						</BaseContact>
					</div>
					<p className="gst-base-contacts-note">
						Zapsáno v obchodním rejstříku dnem 5.3.2001, vedeném Městským soudem
						v Praze, oddíl C, vložka č. 82185.
					</p>
				</Content>
			</Block>
			<Block light className="gst-block-contact">
				<Content>
					<h2 id="location">Where can you find us</h2>
					<ContactMap />

					<div className="gst-contact-location-highlights">
						<div className="gst-contact-location-highlight-wrapper">
							<div className="gst-contact-location-highlight">
								<Icon icon="map-pin" />
								<div className="gst-contact-location-highlight-content">
									<div className="gst-contact-location-highlight-title">
										Milady Horákové 57, Prague
									</div>
									<span>50°5'58"N, 14°25'33" E</span>
								</div>
							</div>
						</div>

						<div className="gst-contact-location-highlight-wrapper">
							<div className="gst-contact-location-highlight">
								<Icon icon="pushpin" />
								<div className="gst-contact-location-highlight-content">
									<div className="gst-contact-location-highlight-title">
										Tram stop Letenské náměstí
									</div>
									<span>Lines 1, 8, 12, 25, 26</span>
									<Link
										className="gst-contact-location-highlight-connections"
										to="https://idos.idnes.cz/en/vlakyautobusymhdvse/spojeni/"
										target="_blank"
									>
										<span>Get connection</span>
										<Icon icon="back" />
									</Link>
								</div>
							</div>
						</div>
					</div>

					{/*<h3>Transport tips</h3>*/}
					{/*<Accordion*/}
					{/*	transitionTime={200}*/}
					{/*	transitionCloseTime={200}*/}
					{/*	tabIndex={0}*/}
					{/*	triggerTagName="div"*/}
					{/*	className="gst-contact-transport-tips"*/}
					{/*>*/}
					{/*	<Collapsible trigger="Metro">*/}
					{/*		<p>*/}
					{/*			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut*/}
					{/*			luctus dui id cursus iaculis. Mauris non lacus non nisl finibus*/}
					{/*			varius. Sed vel elit leo. Pellentesque eu odio sem. Pellentesque*/}
					{/*		</p>*/}
					{/*	</Collapsible>*/}
					{/*	<Collapsible trigger="Train">*/}
					{/*		<p>*/}
					{/*			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut*/}
					{/*			luctus dui id cursus iaculis. Mauris non lacus non nisl finibus*/}
					{/*			varius. Sed vel elit leo. Pellentesque eu odio sem. Pellentesque*/}
					{/*			habitant morbi tristique senectus et netus et malesuada fames ac*/}
					{/*			turpis egestas. Curabitur id facilisis metus, ut pulvinar lorem.*/}
					{/*			Vivamus orci lectus, rutrum at consequat nec, feugiat sit amet*/}
					{/*			tellus. Aenean quis sapien semper tellus molestie suscipit.*/}
					{/*			Maecenas vehicula id sem ut finibus. Integer enim dui, cursus et*/}
					{/*			interdum sed, maximus ut est. Vestibulum ut iaculis ante.*/}
					{/*			Quisque vel turpis nunc. Aliquam tristique tortor quis felis*/}
					{/*			facilisis fringilla. Praesent at magna magna. Sed dapibus lectus*/}
					{/*			quis nulla hendrerit aliquam.*/}
					{/*		</p>*/}
					{/*	</Collapsible>*/}
					{/*	<Collapsible trigger="Car">*/}
					{/*		<p>*/}
					{/*			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut*/}
					{/*			luctus dui id cursus iaculis. Mauris non lacus non nisl finibus*/}
					{/*			varius. Sed vel elit leo. Pellentesque eu odio sem. Pellentesque*/}
					{/*			habitant morbi tristique senectus et netus et malesuada fames ac*/}
					{/*			turpis egestas. Curabitur id facilisis metus, ut pulvinar lorem.*/}
					{/*			Vivamus orci lectus, rutrum at consequat nec, feugiat sit amet*/}
					{/*			tellus. Aenean quis sapien semper tellus molestie suscipit.*/}
					{/*			Maecenas vehicula id sem ut finibus. Integer enim dui, cursus et*/}
					{/*			interdum sed, maximus ut est. Vestibulum ut iaculis ante.*/}
					{/*			Quisque vel turpis nunc. Aliquam tristique tortor quis felis*/}
					{/*			facilisis fringilla. Praesent at magna magna. Sed dapibus lectus*/}
					{/*			quis nulla hendrerit aliquam.*/}
					{/*		</p>*/}
					{/*	</Collapsible>*/}
					{/*	<Collapsible trigger="From the airport">*/}
					{/*		<p>*/}
					{/*			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut*/}
					{/*			luctus dui id cursus iaculis. Mauris non lacus non nisl finibus*/}
					{/*			varius. Sed vel elit leo. Pellentesque eu odio sem. Pellentesque*/}
					{/*			habitant morbi tristique senectus et netus et malesuada fames ac*/}
					{/*			turpis egestas. Curabitur id facilisis metus, ut pulvinar lorem.*/}
					{/*			Vivamus orci lectus, rutrum at consequat nec, feugiat sit amet*/}
					{/*			tellus. Aenean quis sapien semper tellus molestie suscipit.*/}
					{/*			Maecenas vehicula id sem ut finibus. Integer enim dui, cursus et*/}
					{/*			interdum sed, maximus ut est. Vestibulum ut iaculis ante.*/}
					{/*			Quisque vel turpis nunc. Aliquam tristique tortor quis felis*/}
					{/*			facilisis fringilla. Praesent at magna magna. Sed dapibus lectus*/}
					{/*			quis nulla hendrerit aliquam.*/}
					{/*		</p>*/}
					{/*	</Collapsible>*/}
					{/*</Accordion>*/}
				</Content>
			</Block>
		</Layout>
	);
};

export default ContactPage;
