export const poiStyle = {
	key: 'poiStyle',
	data: {
		nameDisplay: 'POI',
		description: '',
		definition: {
			rules: [
				{
					styles: [
						{
							size: 16,
							fill: '#ffffff',
							outlineWidth: 2,
							outlineColor: '#5c82e0',
						},
						{
							attributeKey: 'type',
							attributeValues: {
								metro: {
									icon: 'pushpin',
								},
								tram: {
									icon: 'pushpin',
								},
								parking: {
									icon: 'pushpin',
								},
								gisat: {
									size: 32,
									outlineWidth: 3,
									outlineColor: '#5c82e0',
									icon: 'gisat',
									shape: 'pin',
								},
							},
						},
						{
							attributeKey: 'line',
							attributeValues: {
								A: {
									outlineColor: '#5ba75b',
								},
								C: {
									outlineColor: '#fc8d8d',
								},
							},
						},
					],
				},
			],
		},
	},
};

export const metroStyle = {
	key: 'metroStyle',
	data: {
		nameDisplay: 'Metro',
		description: '',
		definition: {
			rules: [
				{
					styles: [
						{
							outlineColor: '#333333',
							outlineWidth: 2,
							outlineOpacity: 1,
						},
						{
							attributeKey: 'name',
							attributeValues: {
								'Metro C': {
									outlineColor: '#fc8d8d',
								},
								'Metro A': {
									outlineColor: '#5ba75b',
								},
								'Metro B': {
									outlineColor: '#eee07e',
								},
							},
						},
					],
				},
			],
		},
	},
};
