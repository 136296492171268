import React from 'react';
import classnames from 'classnames';
import {Icon} from '@gisatcz/ptr-atoms';
import './style.scss';

class Accordion extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			openItemIndex: null,
		};
	}

	onOpening(i) {
		this.setState({
			openItemIndex: i,
		});
	}

	onClosing(i) {
		this.setState({
			openItemIndex: null,
		});
	}

	render() {
		const {children, className, ...restProps} = this.props;

		const childrenWithProps = React.Children.map(
			this.props.children,
			(child, i) => {
				if (React.isValidElement(child)) {
					return React.cloneElement(child, {
						open: i === this.state.openItemIndex,
						onTriggerOpening: this.onOpening.bind(this, i),
						onTriggerClosing: this.onClosing.bind(this, i),
						className: 'ptr-accordion-item',
						triggerClassName: 'ptr-accordion-item-header',
						openedClassName: 'ptr-accordion-item open',
						triggerOpenedClassName: 'ptr-accordion-item-header open',
						contentInnerClassName: 'ptr-accordion-item-body',
						trigger: this.createItemHeader(child.props.trigger),
						...restProps,
					});
				}
				return child;
			}
		);
		const classes = classnames('ptr-accordion', this.props.className);

		return <div className={classes}>{childrenWithProps}</div>;
	}

	createItemHeader(text) {
		return (
			<>
				<span>{text}</span>
				<Icon icon="chevron-left" />
			</>
		);
	}
}

export default Accordion;
