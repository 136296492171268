import React from 'react';
import './style.scss';

class MapOverlay extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showOverlay: true,
		};

		this.overlayTimeout = null;

		this.addOverlay = this.addOverlay.bind(this);
		this.removeOverlay = this.removeOverlay.bind(this);

		this.scheduleAddition = this.scheduleAddition.bind(this);
		this.cancelAddition = this.cancelAddition.bind(this);
	}

	addOverlay() {
		this.setState({
			showOverlay: true,
		});
	}

	removeOverlay() {
		this.setState({
			showOverlay: false,
		});
	}

	scheduleAddition() {
		this.overlayTimeout = setTimeout(() => {
			this.addOverlay();
		}, 1000);
	}

	cancelAddition() {
		if (this.overlayTimeout) {
			clearTimeout(this.overlayTimeout);
		}
	}

	render() {
		return (
			<div
				className={'gst-map-overlay-container'}
				onMouseLeave={this.scheduleAddition}
				onMouseMove={this.cancelAddition}
			>
				{this.state.showOverlay ? (
					<div className={'gst-map-overlay'} onClick={this.removeOverlay}>
						<span>Click to control the map</span>
					</div>
				) : null}
				{this.props.children}
			</div>
		);
	}
}

export default MapOverlay;
